import React, { useContext, Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//eslint-disable-next-line
import { Link, useRouteMatch } from 'react-router-dom';
import { Col, Card, CardBody, Table, CardTitle, Button } from 'reactstrap';

import OfferLineItem from './OfferLineItem';

// Context
import { GlobalContext } from '../../store/Provider';
import { setAlert } from '../../store/actions/alerts';
import { archiveOffer, clearCurrentOffer } from '../../store/actions/offers';

// Translation
import { useTranslation } from 'react-i18next';
import ContainerSpinner from '../layout/ContainerSpinner';

//extra styles
import "../../assets/scss/offer.scss"

const OfferItem = ({ offer, archived }) => {
  const { alertsDispatch, loadingplacesState, offersDispatch } =
    useContext(GlobalContext);

  const { deweenr, dejaart, field_loading_place, offer_lines, field_archived } =
    offer;




  const { t } = useTranslation();
  //eslint-disable-next-line
  const match = useRouteMatch();

  const [loading, setLoading] = useState(false);

  const [sameWeek, setSameWeek] = useState(false);
  useEffect(() => {
    getCurrentDate()
  }, []);

  const getCurrentDate = async () => {
    let currentDate = new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    let weekNumber = Math.ceil(days / 7);
    setSameWeek(parseInt(weekNumber) <= deweenr && startDate.getFullYear() === parseInt(dejaart))
  };

  const onArchive = async () => {
    setLoading(true);
    const res = await archiveOffer(offer)(offersDispatch);
    if (res.code === 400) {
      setAlert(res.msg, 'danger')(alertsDispatch);
    } else if (res.code === 504) {
      setAlert(res.msg, 'warning')(alertsDispatch);
    } else {
      setAlert(res.msg, 'success')(alertsDispatch);
    }
    clearCurrentOffer()(offersDispatch);
    setLoading(false);
  };


  const getLoadingplaceCode = () => {
    if (
      loadingplacesState.loadingplaces.list.filter(
        lp => lp.id === field_loading_place
      )[0]
    ) {
      return loadingplacesState.loadingplaces.list.filter(
        lp => lp.id === field_loading_place
      )[0].lp_code;
    } else {
      return 'Undefined';
    }
    // return loadingplaces.filter(lp => lp.id === field_loading_place)[0].lp_code;
  };

  const getDateOfWeek = function(week, year) {
    var defaultDate = new Date(new Date().getFullYear(), 0, 1)
    defaultDate.setDate(1);
    while(defaultDate.getDay() !== 1) {
      defaultDate.setDate(defaultDate.getDate() + 1)
    }

    let startDate = getFirstDateOfWeek(week, year).toLocaleDateString('nl-BE', {year: "numeric", month: "2-digit", day: "2-digit"});
    let endDate = getLastDayOfWeek(week, year).toLocaleDateString('nl-BE', {year: "numeric", month: "2-digit", day: "2-digit"})

    let date = startDate + ' - ' + endDate
    return date
  }

  const getFirstDateOfWeek = function (weekNumber, year) {
    // Create a new Date object set to January 1st of the given year
    const firstDayOfYear = new Date(year, 0, 1);
    
    // Get the day of the week for January 1st (0 = Sunday, 1 = Monday, etc.)
    const dayOfWeek = firstDayOfYear.getDay();
    
    // Calculate the offset to the first Monday of the year
    const offsetToMonday = (dayOfWeek <= 4 ? dayOfWeek : dayOfWeek - 7);
    
    // Calculate the timestamp for the first Monday of the year
    const firstMonday = new Date(
      firstDayOfYear.getTime() - offsetToMonday * 24 * 60 * 60 * 1000
    );
    
    // Add the offset for the desired week number
    let weekStartDate = new Date(
      firstMonday.getTime() + (weekNumber - 1) * 7 * 24 * 60 * 60 * 1000
    );

    // Add 1 day to weekStartDate
    weekStartDate.setDate(weekStartDate.getDate() + 1);
    
    return weekStartDate;
  }

  const getLastDayOfWeek = function (weekNumber, year) {
    let firstDay = getFirstDateOfWeek(weekNumber, year);
    // Add 6 days to firstDay
    let lastDay = new Date(firstDay.getTime() + 6 * 24 * 60 * 60 * 1000);
    return lastDay;
  }

  return (
    <Fragment>
      <Col lg='12' md='12'>
        <Card className='card-mh'>
          <CardBody>

            <CardTitle>
              {getLoadingplaceCode()} - {dejaart} W{deweenr}{' '}{`( ${getDateOfWeek(deweenr, dejaart)} )`}
              {field_archived !== '1' && !sameWeek && (
                <Button className='btn-sm fl-right' onClick={onArchive}>
                  <span>{t('offers.archiveOffer')}</span>
                </Button>
              )}
            </CardTitle>
            {offer_lines !== '[]' ? (
              <Table responsive className='mb-3 mt-4'>
                <thead>
                  <tr>
                    <th>{t('offers.amountOfPigs')}</th>
                    <th>{t('offers.countryCode')}</th>
                    <th>{t('general.status')}</th>
                    <th>{t('general.vki')}</th>
                  </tr>
                </thead>
                <tbody>
                  {offer_lines.map(offerLine => (
                    <OfferLineItem
                      key={offerLine.nid}
                      offerLine={offerLine}
                      offer={offer}
                      archived={archived}
                    />
                  ))}
                </tbody>
              </Table>
            ) : (
              ''
            )}
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

OfferItem.propTypes = {
  offer: PropTypes.object.isRequired,
};

export default OfferItem;
